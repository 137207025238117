$table-border: $black solid 1px;

.table {
  border-collapse: collapse;
  font-weight: $fw-semi-bold;

  &__row {
    border-top: $table-border;

    &:nth-child(even) {
      background-color: $light-gray;
    }

    &:last-child {
      border-bottom: $table-border;
    }

    &--head {
      background-color: $gray;
    }
  }

  &__data {
    border-right: $table-border;

    &:first-child {
      border-left: $table-border;
    }
  }
}

.table-container {
  overflow-x: scroll;

  @include breakpoint(large up) {
    overflow: unset;
  }

  &--always-scroll {
    max-width: 100%;

    @include breakpoint(large up) {
      overflow-x: scroll;
    }
  }
}
