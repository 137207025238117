.teams-list {
  margin-bottom: $global-margin;

  &__team {
    font-size: 22px;
    font-weight: $fw-semi-bold;
    margin-bottom: $global-margin;
  }
}

.team {
  &__title {
    font-size: 36px;
    font-weight: $fw-black;
  }

  &__action {
    cursor: pointer;

    &--icon {
      font-size: 24px;
      margin-left: $global-margin / 2;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.lineup-player {
  display: flex;
  align-items: center;

  &__action {
    margin-left: $global-margin / 4;
  }

  &__form {
    display: flex;
    align-items: center;

    input {
      width: 100px;
    }
  }

  &__form-actions {
    display: flex;
  }
}

.team-field {
  $small-size: 300px;
  $large-size: 400px;

  height: $small-size;
  width: $small-size;
  position: relative;
  background-size: $small-size;
  background-repeat: no-repeat;
  background-position: center bottom;
  font-size: 12px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255,255,255,0.8);
  }

  @include breakpoint(medium up) {
    font-size: 14px;
    height: $large-size;
    width: $large-size;
    background-size: $large-size;
  }

  &__form-actions {
    position: absolute;
    bottom: -12px;
    display: flex;
    justify-content: space-around;
    width: $small-size;

    @include breakpoint(medium up) {
      width: $large-size;
    }
  }

  &__player {
    position: absolute;
    background-color: $white;
    padding: $global-padding / 4;
    border: 1px $black solid;
    border-radius: 3px;

    &--pitcher {
      bottom: 40%;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &--catcher {
      bottom: 10%;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &--first-base {
      bottom: 40%;
      left: 85%;
      transform: translate(-50%, 0);
    }

    &--second-base {
      bottom: 60%;
      left: 70%;
      transform: translate(-50%, 0);
    }

    &--third-base {
      bottom: 40%;
      left: 15%;
      transform: translate(-50%, 0);
    }

    &--short-stop {
      bottom: 60%;
      left: 30%;
      transform: translate(-50%, 0);
    }

    &--left-fielder {
      bottom: 75%;
      left: 15%;
      transform: translate(-50%, 0);
    }

    &--center-fielder {
      bottom: 90%;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &--right-fielder {
      bottom: 75%;
      left: 85%;
      transform: translate(-50%, 0);
    }
  }
}

.new-team {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__input {
    display: flex;
    width: 300px;
    margin-bottom: $global-margin * 2;
    font-size: 22px;

    @include breakpoint(medium up) {
      width: 400px;
    }
  }
}

.prediction {
  &__summary {
    margin-bottom: $global-margin;
    text-align: center;
  }

  &__explanations {
    display: flex;
    flex-direction: column;
    margin-top: $global-margin;
  }

  &__action {
    display: flex;
    cursor: pointer;
    align-items: center;
    align-self: center;
  }
}
