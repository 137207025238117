@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;900&family=Quicksand&display=swap');

$global-padding: 24px;
$global-margin: 12px;

$black: #000000;
$white: #FFFFFF;
$light-gray: #EBEBEB;
$gray: #C2C2C2;

$red: #BD1E1E;
$light-red: #F7CACA;
$blue: #0093F5;
$midnight-blue: #004777;
$dark-blue: #003152;
$turquoise: #00AFB5;

$fw-extra-light: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-black: 900;
