.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  // From https://loading.io/css/

  @keyframes loading--ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

  &--ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 20px;
  }

  &--ellipsis div {
    position: absolute;
    top: 3px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $gray;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  &--ellipsis div:nth-child(1) {
    left: 8px;
    animation: loading--ellipsis1 0.6s infinite;
  }

  &--ellipsis div:nth-child(2) {
    left: 8px;
    animation: loading--ellipsis2 0.6s infinite;
  }

  &--ellipsis div:nth-child(3) {
    left: 32px;
    animation: loading--ellipsis2 0.6s infinite;
  }

  &--ellipsis div:nth-child(4) {
    left: 56px;
    animation: loading--ellipsis3 0.6s infinite;
  }

  @keyframes loading--ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes loading--ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes loading--ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}
