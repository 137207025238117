.navbar {
  display: flex;
  padding: 0 $global-padding;
  height: 70px;
  align-items: center;
  justify-content: space-between;

  @include breakpoint(small only) {
    padding: 0 $global-padding / 2;
  }

  &__logo {
    height: 48px;
    width: 48px;

    @include breakpoint(medium up) {
      margin-right: $global-margin;
    }
  }

  &__logo-text {
    display: none;

    @include breakpoint(medium up) {
      display: block;
    }
  }

  &__group {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__title {
    margin: 0;
    font-size: 40px;
    text-decoration: none;
    font-weight: $fw-black;
    color: $midnight-blue;
  }

  &__search-form {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__search-input {
    height: 30px;
    width: 120px;
    font-size: 16px;
    margin: 0 $global-margin;

    @include breakpoint(medium) {
      width: 200px;
    }
    @include breakpoint(large up) {
      width: 380px;
    }
  }

  &__search-suggestions {
    margin: 0 $global-margin;

    @include breakpoint(medium) {
      width: 200px;
    }
    @include breakpoint(large up) {
      width: 380px;
    }
  }

  &__search-submit {
    margin: 0 $global-margin;
  }

  &__user {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: $fw-semi-bold;
  }

  &__user-actions {
    position: relative;
    height: 100%;
    padding: $global-padding / 2 $global-padding;

    &:hover {
      background-color: $light-gray;

      .navbar__dropdown {
        display: flex;
        background-color: $white;
      }
    }

    @include breakpoint(small only) {
      padding: $global-padding / 4;
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    width: 150px;
    padding: $global-padding / 2;
    right: 0;
    top: 70px;
    border: 1px $gray solid;
    align-items: center;
    flex-direction: column;

    a {
      font-size: 14px;
      margin-bottom: $global-margin;
    }
  }

  .button {
    @include breakpoint(small only) {
      font-size: 12px;
    }
  }
}
