.button {
  background-color: $white;
  border: $midnight-blue 2px solid;
  color: $midnight-blue;
  font-weight: $fw-bold;
  font-size: 16px;
  padding: $global-padding / 4 $global-padding / 2;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $white;
    background-color: $midnight-blue;
  }

  &--blue {
    background-color: $blue;
    border-color: $blue;
    color: $white;
  }

  &--midnight-blue {
    background-color: $midnight-blue;
    border-color: $midnight-blue;
    color: $white;

    &:hover {
      background-color: $dark-blue;
      border-color: $dark-blue;
    }
  }

  &--red {
    background-color: $red;
    border-color: $red;
    color: $white;
  }

  &--understated {
    font-size: 14px;
    padding: $global-padding / 8 $global-padding / 4;
  }
}
